import { Box } from "@mui/material";
import moon from "assets/images/moon.png";
const LoginBg = ({ children }) => {
  return (
    <>
      <Box className="background-container">
        <Box
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <img src={moon} alt="" className="moon" />
        </Box>
        <Box className="stars"></Box>
        <Box className="twinkling"></Box>
        <Box className="clouds"></Box>
      </Box>
      {children}
    </>
  );
};

export default LoginBg;
