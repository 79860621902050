import { _axios } from "interceptor/http-config";

export const _AuthApi = {
  login: async (data) => {
    return _axios.post("/auth/login", data).then((res) => {
      _AuthApi.storeToken(res.data.data.token);
      _AuthApi.storeRole(res.data.data.role);
    });
  },

  storeToken: (token) => {
    localStorage.setItem("token", token);
  },
  storeRole: (role) => {
    localStorage.setItem("role", role);
  },

  getToken: () => localStorage.getItem("token"),
  getRole: () => localStorage.getItem("role"),

  destroyToken: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    window.location.reload();
  },

  changePassword: async (data) => {
    return _axios.post("/profile/change-password", data);
  },
  resetPass: async (data) => {
    return _axios.post("/admin/reset-password", data);
  },
  verifyCode: async (data) => {
    return _axios.post("/admin/check-code", data);
  },
  passEdit: async (data) => {
    return _axios.post("/admin/edit-password", data);
  },
};
