import loadable from "@loadable/component";

// ============ Auth ============
export const ResetPassword = loadable(() => import("pages/ResetPassword"));
export const PasswordEditForm = loadable(() =>
  import("pages/PasswordEditForm")
);
export const VerificationCodeForm = loadable(() =>
  import("pages/VerificationCodeForm")
);
// ============ Auth ============

export const AdminRouting = loadable(() =>
  import("modules/admin/AdminRouting")
);
export const SettingRouting = loadable(() =>
  import("modules/setting/SettingRouting")
);
export const UserPlanRouting = loadable(() =>
  import("modules/userPlan/UserPlanRouting")
);
export const UserRouting = loadable(() => import("modules/user/UserRouting"));
export const BannersRouting = loadable(() =>
  import("modules/banner/BannersRouting")
);
export const InvetationRouting = loadable(() =>
  import("modules/invitation/InvetationRouting")
);
export const ProInvetationRouting = loadable(() =>
  import("modules/pro-invitaion/ProInvetationRouting")
);
export const CategoryRouting = loadable(() =>
  import("modules/category/CategoryRouting")
);
export const StatisticRouting = loadable(() =>
  import("modules/statistic/StatisticRouting")
);
export const StorePlanRouting = loadable(() =>
  import("modules/storePlan/StorePlanRouting")
);
export const StoreRouting = loadable(() =>
  import("modules/store/StoreRouting")
);
export const PostRouting = loadable(() => import("modules/post/PostRouting"));
export const ReportRouting = loadable(() =>
  import("modules/report/ReportRouting")
);
export const NotificationRouting = loadable(() =>
  import("modules/notification/NotificationRouting")
);
export const RateRouting = loadable(() => import("modules/rate/RateRouting"));
export const PaymentRouting = loadable(() =>
  import("modules/payment/PaymentRouting")
);
export const UsernameReservationRouting = loadable(() =>
  import("modules/usernameReservation/UsernameReservationRouting")
);
export const CoinTransactionRouting = loadable(() =>
  import("modules/coin_transaction/CoinTransactionRouting")
);
export const LeftUpSettingRouting = loadable(() =>
  import("modules/leftup-setting/LeftUpSettingRouting")
);
export const AccountRouting = loadable(() =>
  import("../modules/account/AccountRouting")
);
export const PostPlanRouting = loadable(() =>
  import("../modules/post-plan/PostPlanRouting")
);
export const BoostPostRouting = loadable(() =>
  import("../modules/boost-post/BoostPostRouting")
);
export const ShadowUserRouting = loadable(() =>
  import("../modules/shadow-user/ShadowUserRouting")
);
export const GroupPlanRouting = loadable(() =>
  import("../modules/group/GroupPlanRouting")
);

// ============ DashboardRouting ============
export const DashboardRouting = loadable(() =>
  import("../modules/dashboard/DashboardRouting")
);
// ============ DashboardRouting ============
